// Make sure to call Vue.use(Vuex) first if using a module system
// import axios from 'axios'

export default {
  mutations: {
    removeProgramItem(state, payload) {
      state.programItems = state.programItems.filter((item) => {
        return !(item.uuid === payload.uuid && item.type === payload.type)
      })
    },
    addFormParticipants(state, payload) {
      // state.form.participants = payload
      state.selectedForm = payload
    },
    addProgramItem(state, payload) {
      state.programItems.push(payload)
    },
    resetProgramItems(state) {
      state.programItems = []
 
    },
    setModel(state, payload) {
      state.model = payload
    },
  },
  actions: {
    removeProgramItem({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('removeProgramItem', payload)
        resolve()
      })
    },
    addFormParticipants({ commit }, payload) {
      commit('addFormParticipants', payload)
    },
    addProgramItem({ commit }, payload) {
      commit('addProgramItem', payload)
    },
    setModel({ commit }, payload) {
      commit('setModel', payload)
    },
    resetProgramItems({ commit }) {
      commit('resetProgramItems')
    },
  },
  getters: {
    getProgramItems: (state) => state.programItems,
    // showTeamId: (state) => state.teamId,
    // getParticipants: state => state.selectedForm.participants
  },
  state: {
    selectedForm: [],
    programItems: [],
    model: '',
  },
  name: 'store',
}
