import './../css/app.css'
import.meta.glob('./../fonts/**/*.{woff2,woff}')

import { createInertiaApp, Head, Link } from '@inertiajs/vue3'
import * as Sentry from '@sentry/vue'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { DatePickerPlugin } from '@hammerstone/refine-vue3'
import vClickOutside from 'click-outside-vue3'
import VuePaginate from './plugins/vue-paginate'
import DatePicker from 'vue-datepicker-next'
import PortalVue from 'portal-vue'
import VTooltip from 'v-tooltip'
import { createApp, h } from 'vue'
import { createStore } from 'vuex'
import { createPinia } from 'pinia'

import { ZiggyVue } from '../../vendor/tightenco/ziggy'
// import { ZiggyVue } from 'ziggy-js'
import './bootstrap'
import storeData from './store.js'

const store = createStore(storeData)
const pinia = createPinia()

// dayJs to format dates in frontend
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

createInertiaApp({
  resolve: (name) =>
    resolvePageComponent(
      `./pages/${name}.vue`,
      import.meta.glob('./pages/**/*.vue'),
    ),
  setup({ plugin, props, App, el }) {
    const app = createApp({ render: () => h(App, props), pinia, store })
      .use(plugin)
      .use(ZiggyVue)
      .use(vClickOutside)
      .use(VTooltip)
      .use(VuePaginate)
      .use(DatePicker)
      .use(PortalVue)
      .use(DatePickerPlugin, { DatePicker })
      .use(pinia)
      .use(store)
      .component('Link', Link)
      .component('Head', Head)

    if (import.meta.env.PROD) {
      Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
        environment: import.meta.env.VITE_SENTRY_ENV,
        release: import.meta.env.VITE_APP_VERSION,
        trackComponents: true,
        tracesSampleRate: 0.5,
        sendDefaultPii: true,
        replaysSessionSampleRate: 0.3,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.httpClientIntegration(),
          Sentry.replayIntegration(),
        ],
      })
    }

    app.config.globalProperties.$date = dayjs

    app.mount(el)
  },
  progress: {
    color: '#fc1862',
  },
})

if (import.meta.env.PROD) {
  var APP_ID = import.meta.env.VITE_INTERCOM_APP
  ;(function () {
    var w = window
    var ic = w.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', w.intercomSettings)
    } else {
      var d = document
      var i = function () {
        i.c(arguments)
      }
      i.q = []
      i.c = function (args) {
        i.q.push(args)
      }
      w.Intercom = i
      var l = function () {
        var s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = 'https://widget.intercom.io/widget/' + APP_ID
        var x = d.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(s, x)
      }
      if (document.readyState === 'complete') {
        l()
      } else if (w.attachEvent) {
        w.attachEvent('onload', l)
      } else {
        w.addEventListener('load', l, false)
      }
    }
  })()

  if (window.Intercom) {
    window.Intercom('boot', {
      app_id: APP_ID,
    })
  }
}
